import React from 'react'
import { DashboardFooter, FooterContainer } from './styles'
import careers from 'assets/logo.svg'

const Footer = () => {

  return (
    <FooterContainer>
        <p>SAHCO 2022 | All Rights Reserved</p>
    </FooterContainer>
  )
}

export default Footer

export const LoggedInFooter = () => {

  return (
    <DashboardFooter>
      <div className='footer_img'>
        <img src={careers} alt='Careers Logo'/>
      </div>
      <p>SAHCO 2022 | All Rights Reserved</p>
    </DashboardFooter>
  )
}