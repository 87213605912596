import Error from 'layout/loggedIn/Error'
import React from 'react'
import { ErrorFlex } from './styles'
import notFound from 'assets/images/404.svg'
import { Link } from 'react-router-dom'
import { BiLeftArrowCircle } from 'react-icons/bi'

const NotFound = () => {

  return (
    <Error>
        <ErrorFlex>
            <div className='back'>
                <BiLeftArrowCircle size={24}/>
                <Link to='/dashboard'>
                    Back to Homepage
                </Link>
            </div>
            <div className='notFound_container'>
                <img alt='Not Found' src={notFound}/>
                <p>Page not found</p>
            </div>
        </ErrorFlex>
    </Error>
  )
}

export default NotFound