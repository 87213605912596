import styled from 'styled-components'

export const ErrorFlex = styled.div`
    width: 100%;
    height: 593px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    .back {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 10px;
        width: 216px;
        height: 24px;
        position: relative;
        top: -18rem;
        left: -3rem;

        a {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 22px;
            color: #292929;
            text-decoration: none;
        }
    }

    .notFound_container {
        width: 890px;
        height: 100%;
        border-radius: 10px;

        img {
            width: 100%;
            height: 90%;
            object-fit: contain;
        }
    
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 22px;
            letter-spacing: 0.015em;
            color: #000000;
            text-align: center;
        }
    }

`