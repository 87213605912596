import { combineReducers } from "redux";
import authReducer from "./index";
import appReducer from "./users";

const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer
});

export default rootReducer;
