import * as type from "../action/types";

const initialState = {
  isAuth: false,
  loading: false,
  error: null,
  token: {},
  user: {},
  search: null,
}

const auth = ( state = initialState, action ) => {
    
    switch (action.type) {
        case type.LOADING:
          return {
            ...state,
            loading: action.payload,
          };

        case type.SIGN_OUT:
          localStorage.clear();
          localStorage.removeItem('token')
          return {
            ...state,
            isAuth: false,
            token: null,
            user: null,
            loading: false,
          };

        case type.SIGN_IN:
          localStorage.setItem("token", action.payload.token)
          return {
            ...state,
            isAuth: true,
            token: action.payload.token,
            user: action.payload.user,
            loading: false,
          };

        case type.ERROR:
          return {
            ...state,
            error: action.payload,
          };

        case type.SEARCH:
          return{
            ...state,
            search: action.payload,
          };

        default:
          return { ...state };
    }
};
    
export default auth;

