export const LOADING = "LOADING";
//Auth
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_IN = "SIGN_IN";
export const SET_IS_AUTH = 'SET_IS_AUTH'
export const ERROR = "ERROR";
export const SEARCH = "SEARCH";
//Test
export const START_TEST = "START_TEST";
export const SUBMIT_TEST = 'SUBMIT_TEST'
//Result
export const GET_RESULTS = 'GET_RESULTS'

//Applicant
export const GET_APPLICANTS = 'GET_APPLICANTS'

//Question Category
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORY = 'GET_CATEGORY'

//Question
export const GET_QUESTION = 'GET_QUESTION'
export const GET_QUESTIONS = 'GET_QUESTIONS'
export const ARCHIVE_QUESTION = 'ARCHIVE_QUESTION'