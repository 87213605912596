import React from 'react'
import { LazyContainer } from './styles'
import logo from 'assets/logo.svg'

const Lazy = () => {

  return (
    <LazyContainer>
        <div className='lazy_image'>
            <img alt='Lazy Loader Logo' src={logo}/>
        </div>
    </LazyContainer>
  )
}

export default Lazy