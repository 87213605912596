import * as type from "../action/types";

const initialState = {
    applicants: [],
    results: [],
    questionTypes: [],
    questionType: [],
    questions: [],
    question: [],
    archive: [],
    test: null
}

const appReducer = (state = initialState, action) => {

    switch (action.type) {

        case type.GET_APPLICANTS: 
            return {
                ...state,
                applicants: action.payload
            }

        case type.START_TEST: 
            return {
                ...state,
                test: action.payload
            }

        case type.GET_RESULTS: 
            return {
                ...state,
                results: action.payload
            }

        case type.GET_CATEGORIES: 
            return {
                ...state,
                questionTypes: action.payload
            }

        case type.GET_CATEGORY: 
            return {
                ...state,
                questionType: action.payload
            }
        case type.GET_QUESTIONS: 
            return {
                ...state,
                questions: action.payload
            }
        case type.GET_QUESTION: 
            return {
                ...state,
                question: action.payload
            }
        case type.ARCHIVE_QUESTION: 
            return {
                ...state,
                archive: action.payload
            }

        default: 
            return { ...state };
    }
}

export default appReducer;