import styled from "styled-components";

export const LayoutContainer = styled.div`
    width: 100%;

    .children {
        background: #F5F5F5;
        width: ${(props) => (props.width ? '100%' : 'calc(100% - 269px)')};
        position: relative;
        left: ${(props) => (props.width ? '0' : '269px')};
        padding: 32px 80px .3rem 29px;
    }
`