import styled from "styled-components";

export const FooterContainer = styled.div`
    width: 231px;
    height: 19px;
    text-align: center;
    position: relative;
    top: 8.5rem;
    z-index: 1;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #292929;
    }

    @media screen and (max-width: 768px){
        top: 3.5rem;
    }
`

export const TestFooter = styled.div`
    width: 100%;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14.0476px;
        line-height: 22px;
        text-align: center;
        color: #292929;
    }

    @media screen and (max-width: 768px){

        p {
            font-size: 10px;
            line-height: 19px;
        }
    }
`

export const DashboardFooter = styled.div`
    width: 100%;
    height: 35.12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    .footer_img {
        width: 167.13px;
        height: 35.12px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14.0476px;
        line-height: 22px;
        text-align: center;
        color: #292929;
        flex: none;
        order: 1;
        flex-grow: 0;
    }
`