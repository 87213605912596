import React, { useEffect, useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { BsBell, BsFileText, BsSearch, BsArchive, BsFileEarmarkExcel } from 'react-icons/bs'
import { FiLogOut, FiUsers } from 'react-icons/fi'
import { HiOutlineCog6Tooth } from 'react-icons/hi2'
import avatar from 'assets/images/user.svg'
import sahcoLogo from 'assets/images/sifax-sahco-logo-brand-1-removebg-preview 1.svg'
import { MdOutlineDashboard } from 'react-icons/md'
import { NavbarContainer, SidebarContainer } from './styles'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUnreadActivity, markActivityRead, markAllActivityRead } from 'services/activity.service'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { io } from 'socket.io-client'
import { environment } from 'config/environment'

const Navbar = () => {
    const [notify, setNotify] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [activity, setActivity] = useState([])
    const { user } = useSelector((store) => store.auth)
    const dispatch = useDispatch()

    const { api: { uri } } = environment

    const handleSuccess = (message) => {
        enqueueSnackbar(message, {
            variant: "success",
            anchorOrigin: {
            vertical: "top",
            horizontal: "right",
            },
        });
    };
  
    const handleFail = (message) => {
        enqueueSnackbar(message, {
            variant: "error",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    };

    const fetchActivities = async () => {

        try {
            const res = await getUnreadActivity()
            setActivity(res.data)
        } catch (e) {
            dispatch({type: 'ERROR', payload: e?.message})
        }
    }

    const markRead = async (id) => {

        try {
           const res = await markActivityRead(id)
           
           if(res.success) {
                handleSuccess(res.message)
                await fetchActivities()
           }
        } catch (e) {
            dispatch({type: 'ERROR', payload: e?.message})
            handleFail(e?.message)
        }
    }

    useEffect(() => {
        (async function() {

            await fetchActivities()
        }
        )()
    }, [])

    const socket = io(`${uri.slice(0, uri.length - 3)}?channelName=${user?.role}`, {
        transports: ['polling', 'websocket'],
    })

    socket.on('newSubmission', (data => {
        handleSuccess(data?.message)
    }))

    const markAllAsRead = async () => {
        try {
            
            const res = await markAllActivityRead()

            if(res.success) {
                handleSuccess(res.message)
                await fetchActivities()
           }

        } catch (e) {
            dispatch({type: 'ERROR', payload: e?.message})
            handleFail(e?.message)
        }
    }

  return (
    <NavbarContainer width={true}>
        <div className='navbar_logo'>
            <img src={sahcoLogo} alt='SAHCO LOGO'/>
        </div>
        <p className='user_name'>Hi {user?.firstName},</p>
        <div className='navbar_control'>
            <div className='search_bar'>
                <div className='notify_bell'>
                    <BsSearch/>
                </div>
            </div>
            <div className='search_bar'>
                <div className='notify_bell' onClick={()=>setNotify(!notify)}>
                    <div className='counter'>
                        <p>{activity?.length}</p>
                    </div>
                    <BsBell/>
                </div>
                {notify && (
                    <div className='notification_bar'>
                        <div className='header'>
                            <p className=''>Notifications</p>
                        </div>
                        <ul className='notify_list'>
                            {activity?.map(act => (
                                <li className='notify_item' key={act.id} onClick={() => {markRead(act.id)}}>
                                    <div className='notify_img'>
                                        <BsFileEarmarkExcel color='green' size={18}/>
                                    </div>
                                    <div className='notify_message'>
                                        <p className='notify_name'>{act.user.firstName} {act.user.lastName}</p>
                                        <p className='notify_text'>{act.name}</p>
                                    </div>
                                    <p className='notify_time'>{moment(act.created_time).fromNow()}</p>
                                </li>
                            ))}
                        </ul>
                        <div className='notification_footer' onClick={markAllAsRead}>
                            <p className='notify_footer'>Mark all as read</p>
                        </div>
                    </div>
                )}
            </div>
            <div className='avatar'>
                <img src={avatar} alt='User'/>
            </div>
        </div>
    </NavbarContainer>
  )
}

export default Navbar

export const Sidebar = () => {
    const dispatch = useDispatch()

    const handleLogout = () => {

        dispatch({
            type: 'SIGN_OUT',
        })
    }

    return (
        <SidebarContainer>
            <div className='nav_logo'>
                <img src={sahcoLogo} alt='SAHCO Logo'/>
            </div>
            <ul className='nav_items'>
                <li>
                    <NavLink exact activeClassName="active" to='/dashboard'>
                        <MdOutlineDashboard /> Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/results'>
                        <BsFileText /> Results
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/applicants'>
                        <FiUsers /> Appplicants
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/bank'>
                        <AiOutlineQuestionCircle /> Question Bank
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/archive'>
                        <BsArchive /> Archived
                    </NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" to='/help'>
                        <HiOutlineCog6Tooth /> Help
                    </NavLink>
                </li>
            </ul>
            <div className='logout_div' onClick={handleLogout}>
                <FiLogOut/>
                <p>Logout</p>
            </div>
        </SidebarContainer>
    )
}