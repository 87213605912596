import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({roleRequired, children}) => {

    const { user, isAuth } = useSelector((store) => store.auth)
    if(!(isAuth)) {
        return <Navigate to='/' replace/>
    } else if(!roleRequired.includes(user?.role)) {
        return <Navigate to='/permission-denied' replace/>
    }

    return children;
}

export default ProtectedRoute;