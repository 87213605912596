import { LoggedInFooter } from 'components/footer/Footer'
import Navbar from 'components/navbar/Navbar'
import React from 'react'
import { LayoutContainer } from './styles'

const Error = ({children}) => {

  return (
    <LayoutContainer width={true}>
       <Navbar/>
       <div className='children'>
          {children}
          <LoggedInFooter/>
        </div> 
    </LayoutContainer>
  )
}

export default Error