import API from "utils/BackendAPI";

export const getActivity = async (page, size) => {

    try {
        const res = await API.get(`/activity?page=${page}&size=${size}`)

        return res?.data
        
    } catch (e) {

        throw e?.response?.data
    }
}

export const getUnreadActivity = async () => {

    try {
        const res = await API.get(`/activity/unread`)

        return res?.data
        
    } catch (e) {

        throw e?.response?.data
    }
}

export const markActivityRead = async (id) => {

    try {
        const res = await API.patch(`/activity/${id}`)

        return res?.data
        
    } catch (e) {

        throw e?.response?.data
    }
}

export const markAllActivityRead = async () => {

    try {
        const res = await API.patch(`/activity/read/all`)

        return res?.data
        
    } catch (e) {

        throw e?.response?.data
    }
}