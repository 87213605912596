import { lazy, Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Lazy from 'layout/Lazy/Lazy';
import NotFound from 'pages/NotFound/NotFound';
import ProtectedRoute from 'utils/ProtectedRoutes';

const Instruction = lazy(()=> import('pages/Instruction/Instruction'))
const SignUp = lazy(() => import('pages/Auth_Pages/SignUp'))
const Login = lazy(() => import('pages/Auth_Pages/Login'))
const Exit = lazy(() => import('pages/Exit'))
const Assessment = lazy(() => import('pages/Assessment/Assessment'))
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'))
const Results = lazy(() => import('pages/Results/Results'))
const Applicants = lazy(() => import('pages/Applicants/Applicants'))
const QuestionBank = lazy(() => import('pages/QuestionBank/QuestionBank'))
const Archive = lazy(() => import('pages/Archive/Archived'))
const AddApplicant = lazy(() => import('pages/AddApplicant/AddApplicant'))
const AddQuestion = lazy(() => import('pages/AddQuestion/AddQuestion'))
const EditQuestion = lazy(() => import('pages/AddQuestion/EditQuestion'))
const ViewQuestion = lazy(() => import('pages/ViewQuestion/ViewQuestion'))

function App() {
  return (
    <SnackbarProvider>
      <Suspense fallback={<Lazy/>}>
        <Routes>
          <Route path='/' element={<Login/>}/>
          <Route path='/signup' element={<SignUp/>}/>
          <Route path='/instructions' element={
            <ProtectedRoute roleRequired={['APPLICANT']}>
              <Instruction/>
            </ProtectedRoute>}/>
          <Route path='/exit' element={
            <ProtectedRoute roleRequired={['APPLICANT']}>
              <Exit/>
            </ProtectedRoute>
          }/>
          <Route path='/dashboard' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <Dashboard/>
            </ProtectedRoute>
          }/>
          <Route path='/results' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <Results/>
            </ProtectedRoute>
          }/>
          <Route path='/test/:job_title/:job_level' element={
            <ProtectedRoute roleRequired={['APPLICANT']}>
              <Assessment/>
            </ProtectedRoute>
          }/>
          <Route path='/applicants' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <Applicants/>
            </ProtectedRoute>
          }/>
          <Route path='/bank' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <QuestionBank/>
            </ProtectedRoute>
          }/>
          <Route path='/archive' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <Archive/>
            </ProtectedRoute>
          }/>
          <Route path='/applicants/new' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <AddApplicant/>
            </ProtectedRoute>
          }/>
          <Route path='/bank/new/:id' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <AddQuestion/>
            </ProtectedRoute>
          }/>
          <Route path='/bank/edit/:id' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <EditQuestion/>
            </ProtectedRoute>
          }/>
          <Route path='/bank/view/:id' element={
            <ProtectedRoute roleRequired={['ADMIN']}>
              <ViewQuestion/>
            </ProtectedRoute>
          }/>
          <Route path='*' element={<NotFound/>}/>
        </Routes>
      </Suspense>
    </SnackbarProvider>
  );
}

export default App;
