import styled from "styled-components";

export const NavbarContainer = styled.div`
    height: 99px;
    width: ${(props) => (props.width === true ? '100%' : 'calc(100% - 269px)')};
    background: #FFFFFF;
    padding: 44px 86px 19px 32px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    left: ${(props) => (props.width ? '0' : '269px')};

    .navbar_logo {
        display: ${(props) => (props.width === true ? 'block' : 'none')};
        width: 109px;
        height: 52.2px;
        position: relative;
        left: 100px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .user_name {
        font-family: 'Playfair Display', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #292929;
        position: relative;
        left: ${(props) => (props.width === true ? '-356px' : '0')};
    }

    .navbar_control {
        width: 100px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search_bar {
            width: 24px;
            height: 24px;
            cursor: pointer;

            .notify_bell {
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .counter {
                    width: 20px;
                    height: 20px;
                    background: #27AE60;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    left: 17px;
                    top: -7px;
                    z-index: 1;
    
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 8.33333px;
                        line-height: 6px;
                        color: #FFFFFF;
                    }
                }
            }


            .notification_bar {
                width: 279px;
                height: 361px;
                background: #FFFFFF;
                border: 1px solid rgba(223, 112, 36, 0.15);
                border-radius: 0px 0px 10px 10px;
                position: relative;
                top: 17px;
                left: -13rem;
                z-index: 1;

                .header {
                    width: 100%;
                    height: 42px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 80px;
                    gap: 10px;
                    background: #FFFFFF;
                    border: 1px solid rgba(223, 112, 36, 0.15);
                    border-radius: 0px;

                    .notify_title {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        color: #292929;
                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                }
            }

            .notify_list {
                width: 100%;
                height: 277px;
                list-style-type: none;
                overflow-y: scroll;

                .notify_item {
                    width: 100%;
                    height: 52px;
                    border: 1px solid rgba(41, 41, 41, 0.12);
                    border-radius: 2px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    gap: 8px;

                    .notify_img {
                        width: 24px;
                        height: 24px;
                        background: #D9D9D9;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .notify_message {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 0px;
                        width: 150px;
                        height: 32px;

                        .notify_name {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 18px;
                            color: #292929;
                            flex: none;
                            order: 0;
                            flex-grow: 0;
                            white-space: nowrap;
                        }

                        .notify_text {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 16px;
                            color: #292929;
                            flex: none;
                            order: 1;
                            flex-grow: 0;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    .notify_time {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 10px;
                        line-height: 15px;
                        color: #292929;
                        flex: none;
                        order: 2;
                        flex-grow: 0;
                        white-space: nowrap;
                    }
                }


                &::-webkit-scrollbar {
                    width: 6px !important;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1 !important;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #292929 !important;
                    border-radius: 4px;
                }
            }

            .notification_footer {
                width: 100%;
                height: 42px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px 70px;
                gap: 10px;
                background: #FFFFFF;
                border: 1px solid rgba(223, 112, 36, 0.15);
                border-radius: 0px 0px 10px 10px;

                .notify_footer {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(9, 44, 76, 0.9);
                }
            }
        }

        .avatar {
            width: 24px;
            height: 24px;
            border-radius: 50px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 50px;
            }
        }
    }
`

export const SidebarContainer = styled.div`
    width: 269px;
    height: auto;
    background: #FFFFFF;
    padding: 34px 21px 0 60px;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .nav_logo {
        width: 109px;
        height: 52.2px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .nav_items {
        width: 100%;
        height: 364px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 40px;
        margin-top: 3rem;

        li {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: left;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            flex: none;
            order: 1;
            flex-grow: 0;

            a {
                text-decoration: none;
                color: inherit;
                display: flex;
                align-items: center;
                justify-content: left;
            }

            svg {
                margin-right: 10px;
                font-size: 24px;
            }

            &:hover {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                padding-left: 5px;
                gap: 10px;
                height: 44px;
                width: 100%;
                background: rgba(223, 112, 36, 0.5);
                border-radius: 5px;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
                flex: none;
                order: 1;
                flex-grow: 0;
                transition: 200ms all ease-in;
            }
        }
        .active {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            gap: 10px;
            height: 44px;
            width: 100%;
            background: #DF7024;
            border-radius: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
            flex: none;
            order: 1;
            flex-grow: 0;
            transition: 200ms all ease-in;
            white-space: nowrap;

            svg {
                margin-right: 0;
            }

            &:hover {
                padding-left: 0;
            }
        }
    }

    .logout_div {
        width: 94px;
        height: 254px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 60px;
        gap: 10px;
        cursor: pointer;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: #292929;
            flex: none;
            order: 1;
            flex-grow: 0;
        }

        svg {
            color: #292929;
            font-size: 24px;
        }
    }
`